import React, { useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  Center,
  Spinner,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { useLazyGetNotificationsDMQuery, useUpdateNotificationsDMMutation } from 'src/app/api/notificationApi';
import { FieldValues, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { TOAST_STATUS } from 'src/const';
import { colors } from 'src/theme/foundations/colors';
import { getSwitchStyles } from 'src/theme/components/toggle';

type DeliveryMethod = {
  name: string;
  enabled: boolean;
  show?: boolean;
};

type UserDeliveryMethod = {
  event_ui_name: string;
  event_type: string;
  delivery_methods: DeliveryMethod[];
};

export const UserAlertMethod = () => {
  const { userId } = useParams<{ userId: string }>();
  const toast = useToast();

  const [updateDeliveryMethods, { isLoading: isUpdatingDeliveryMethods }] = useUpdateNotificationsDMMutation();
  const [fetchDeliveryMethods, { data: deliveryMethodsCore, isLoading }] = useLazyGetNotificationsDMQuery();

  const { control, register, handleSubmit } = useForm();
  useFieldArray({ control, name: 'userDeliveryMethodsForm' });

  // Delivery methods default value
  const deliveryMethods = deliveryMethodsCore || [];

  // Get unique delivery methods
  const uniqueDeliveryMethods = useMemo(() => {
    const methodSet = new Map<string, DeliveryMethod>();

    deliveryMethods.forEach(({ delivery_methods }: any) => {
      delivery_methods.forEach((method: any) => {
        if (!methodSet.has(method.name)) {
          methodSet.set(method.name, { ...method });
        }
      });
    });

    return Array.from(methodSet.values());
  }, [deliveryMethods]);

  const onSubmit = async (values: FieldValues) => {
    const payload = deliveryMethods.map((deliveryMethod: UserDeliveryMethod) => {
      const formValues = values.userDeliveryMethodsForm[deliveryMethod.event_type] || {};
      return {
        ...deliveryMethod,
        delivery_methods: uniqueDeliveryMethods.map(({ name }) => ({
          name,
          enabled: formValues[name] || false,
        })),
      };
    });

    try {
      await updateDeliveryMethods({ payload }).unwrap();
      toast({ status: TOAST_STATUS.Success, title: 'Alert method preferences updated' });
    } catch (error: any) {
      toast({ status: TOAST_STATUS.Error, title: error?.data?.message || 'Failed to update alert method preferences' });
    }
  };

  useEffect(() => {
    if (userId) fetchDeliveryMethods({ uids: userId });
  }, [userId, fetchDeliveryMethods]);

  return (
    <Box bgColor={colors.DMGray} p={3} height="full" borderRadius="8px">
      {!isLoading && deliveryMethods.length > 0 ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <TableContainer>
            <Table
              variant="normal"
              w="full"
              sx={{ tableLayout: 'fixed', borderSpacing: '0 10px', borderCollapse: 'separate' }}
            >
              <Thead>
                <Tr>
                  <Th textTransform="capitalize" w="30%" fontSize={16} fontWeight={500} fontFamily={'inherit'}>
                    Application
                  </Th>
                  {uniqueDeliveryMethods.map(({ name }, index) => (
                    <Th
                      key={index}
                      textTransform="capitalize"
                      textAlign="center"
                      w="10%"
                      fontSize={16}
                      fontWeight={500}
                      fontFamily={'inherit'}
                    >
                      {name}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {deliveryMethods.map((deliveryMethod: any) => (
                  <Tr key={deliveryMethod.event_ui_name} bg="white">
                    <Td fontWeight="600" p={5} borderLeftRadius="8px">
                      {deliveryMethod.event_ui_name}
                    </Td>
                    {uniqueDeliveryMethods.map(({ name }, index) => {
                      const isChecked =
                        deliveryMethod.delivery_methods.find((method: any) => method.name === name)?.enabled || false;
                      return (
                        <Td key={index} textAlign="center">
                          <Switch
                            sx={{ ...getSwitchStyles(isUpdatingDeliveryMethods), display: 'inline-flex' }}
                            size="sm"
                            isDisabled={
                              isUpdatingDeliveryMethods ||
                              (deliveryMethod.event_type !== 'health_status_change' && name === 'Mobile (SMS)')
                            }
                            defaultChecked={isChecked}
                            {...register(`userDeliveryMethodsForm.${deliveryMethod.event_type}.${name}`)}
                          />
                        </Td>
                      );
                    })}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>

          <Box py={5} ml="1rem">
            <Button
              type="submit"
              variant="data-mind"
              size="lg"
              borderRadius="full"
              isLoading={isUpdatingDeliveryMethods}
            >
              Save changes
            </Button>
          </Box>
        </form>
      ) : (
        <Center py={16} w="full">
          <Spinner size="lg" />
        </Center>
      )}
    </Box>
  );
};
